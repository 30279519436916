// 表格键盘导航事件
export default {
    data(){
        return {
            canKeyTableColumnList:[],//可以键盘导航的表格列详情
            realCanKeyTableColumnList:[],//实际可以键盘导航的表格列详情
            tableKey:'',
            hasTableKey:'',
        }
    },
    watch:{
        canKeyTableColumnList:{
            handler(newVal){
                //console.log(newVal,'tableKeydown')
                //以为部分组件中的表格，部分列存在动态显示，所以表格中的字段列需要重新排序，这里重新定义一个数组记录实际可以键盘导航的表格列详情。
                this.realCanKeyTableColumnList = newVal.filter(item=>!item.isDeleted)
                const hasKey = newVal.some(obj => obj.hasOwnProperty('fieldIsDisplay'));
                if(hasKey)
                    this.realCanKeyTableColumnList = this.realCanKeyTableColumnList.filter(item=>item.fieldIsDisplay)
                //console.log(this.realCanKeyTableColumnList,'tableKeydown')
            },
            deep:true
        }
    },
    created(){
        this.realCanKeyTableColumnList = this.canKeyTableColumnList.filter(item=>!item.isDeleted)
    },
    methods:{
        handleTableKeydown(e,row,templateName,rowindex, columnindex){
            console.log('开始键盘事件',e);
            this.$nextTick(() => {
              this.nextCellFocus(e.key,templateName,rowindex, columnindex)  
            });
        },
        //具体处理键盘事件
        nextCellFocus(key,templateName,rowindex, columnindex){
            //右键、下键、enter键，如果已经是最后一个键盘盗汗列，默认新增下一行
            if(key=="ArrowRight"||key == "Enter"||key=="ArrowDown"){
                if(columnindex==this.realCanKeyTableColumnList[this.realCanKeyTableColumnList.length-1].columnindex){
                    //已经是最后一个键盘导航列
                    this.handleAddRow(rowindex,columnindex);
                }
            }
            if(key=="ArrowDown"){//下
                let rowIndex = rowindex+1
                if(this.$refs[templateName +'_'+ rowIndex +'_'+ columnindex]){
                    let refName = templateName +'_'+ rowIndex +'_'+ columnindex;
                    this.handleKeyFocus(columnindex,key,refName);
                }
                this.handleAddRow(rowindex,columnindex);
                // else if(!this.$refs[templateName +'_'+ rowIndex +'_'+ columnindex]&&rowIndex<=100){
                //   this.nextCellFocus(key,templateName,rowIndex, columnindex)
                // }
            }
            if(key=="ArrowUp"){//上
                let rowIndex = rowindex-1
                if(this.$refs[templateName +'_'+ rowIndex +'_'+ columnindex]){
                    let refName=templateName +'_'+ rowIndex +'_'+ columnindex;
                    this.handleKeyFocus(columnindex,key,refName);
                }
                // else if(!this.$refs[templateName +'_'+ rowIndex +'_'+ columnindex]&&rowIndex>=0){
                //   this.nextCellFocus(key,templateName,rowIndex, columnindex)
                // }
            }
            if(key=="ArrowRight"||key == "Enter"){//右
                let nextIndex = 0;
                let curColumnInfo ={};//获取当前列信息
                this.realCanKeyTableColumnList.forEach((item,index)=>{
                    if(columnindex==item.columnindex){
                        nextIndex = index+1;
                        curColumnInfo = item;
                    }
                })
                //如果当前列是下拉框或者时间日期类型，默认关闭当前列的下拉框
                if(curColumnInfo.type=='select'||curColumnInfo.type=='selectRemote'||curColumnInfo.type=='date'||curColumnInfo.componentRefName=='remoteSelect'){
                    let currentRefName = curColumnInfo.key +'_'+ rowindex +'_'+ columnindex;
                    if(this.$refs[currentRefName] instanceof Array){
                        if(this.$refs[currentRefName][0].$refs[curColumnInfo.componentRefName]){
                            this.$refs[currentRefName][0].$refs[curColumnInfo.componentRefName].blur();
                        }else{
                            this.$refs[currentRefName][0].blur();
                            if(curColumnInfo.type=='date'){
                                this.$refs[currentRefName][0].hidePicker();
                            }
                        }
                    }else{
                        if(curColumnInfo.componentRefName&&this.$refs[currentRefName].$refs[curColumnInfo.componentRefName]){
                            this.$refs[currentRefName].$refs[curColumnInfo.componentRefName].blur();
                        }else{
                            this.$refs[currentRefName].blur();
                            if(curColumnInfo.type=='date'){
                                this.$refs[currentRefName].hidePicker();
                            }
                        }
                    }
                    
                }
                columnindex = this.realCanKeyTableColumnList[nextIndex].columnindex;
                templateName = this.realCanKeyTableColumnList[nextIndex].key;
                if(this.$refs[templateName +'_'+ rowindex +'_'+ columnindex]){
                    let refName = templateName +'_'+ rowindex +'_'+ columnindex;
                    this.handleKeyFocus(columnindex,key,refName);
                }
                // else if(!this.$refs[templateName +'_'+ rowindex +'_'+ columnindex]&&columnindex<=100){
                //   this.nextCellFocus(key,templateName,rowindex, columnindex)
                // }
            }
            if(key=="ArrowLeft"){//左
                if(columnindex==this.realCanKeyTableColumnList[0].columnindex){
                    //已经是第一个键盘导航列
                    return;
                }
                let curColumnInfo ={};//获取当前列信息
                this.realCanKeyTableColumnList.forEach((item,index)=>{
                    if(columnindex==item.columnindex){
                        columnindex = this.realCanKeyTableColumnList[index-1].columnindex;
                        templateName = this.realCanKeyTableColumnList[index-1].key;
                        curColumnInfo = item;
                    }
                })
                //如果当前列是下拉框或者时间日期类型，默认关闭当前列的下拉框
                if(curColumnInfo.type=='select'||curColumnInfo.type=='date'||curColumnInfo.type=='selectRemote'||curColumnInfo.componentRefName=='remoteSelect'){
                    let currentRefName = curColumnInfo.key +'_'+ rowindex +'_'+ curColumnInfo.columnindex;
                    if(this.$refs[currentRefName] instanceof Array){
                        if(this.$refs[currentRefName][0].$refs[curColumnInfo.componentRefName]){
                            this.$refs[currentRefName][0].$refs[curColumnInfo.componentRefName].blur();
                        }else{
                            this.$refs[currentRefName][0].blur();
                            if(curColumnInfo.type=='date'){
                                this.$refs[currentRefName][0].hidePicker();
                            }
                        }
                    }else{
                        if(this.$refs[currentRefName].$refs[curColumnInfo.componentRefName]){
                            this.$refs[currentRefName].$refs[curColumnInfo.componentRefName].blur();
                        }else{
                            this.$refs[currentRefName].blur();
                            if(curColumnInfo.type=='date'){
                                this.$refs[currentRefName].hidePicker();
                            }
                        }
                    }
                    
                }
                if(this.$refs[templateName +'_'+ rowindex +'_'+ columnindex]){
                    let refName = templateName +'_'+ rowindex +'_'+ columnindex;
                    this.handleKeyFocus(columnindex,key,refName);
                }else if(!this.$refs[templateName +'_'+ rowindex +'_'+ columnindex]&&columnindex>=0){
                    this.nextCellFocus(key,templateName,rowindex, columnindex)
                }
            }
        },
        //聚焦到表单具体焦点内
        handleKeyFocus(columnindex,type,refName){
            let curCanKeyTableColumnListIndex = 0;
            let curCanKeyTableColumnListInfo = {};
            this.realCanKeyTableColumnList.forEach((item,index)=>{
                if(columnindex==item.columnindex){
                    if(type=="rightEnter"){
                        curCanKeyTableColumnListIndex = 0;
                    }else{
                        curCanKeyTableColumnListIndex = index;
                        curCanKeyTableColumnListInfo = item;
                    }
                }
            })
            if(this.realCanKeyTableColumnList[curCanKeyTableColumnListIndex].componentRefName){
                //封装的组件，取下面的select焦点还要再绕一层。
                if(this.$refs[refName] instanceof Array){
                    this.$refs[refName][0].$refs[this.realCanKeyTableColumnList[curCanKeyTableColumnListIndex].componentRefName].focus();
                }else{
                    this.$refs[refName].$refs[this.realCanKeyTableColumnList[curCanKeyTableColumnListIndex].componentRefName].focus();
                }
                
            }else{
                if(this.$refs[refName] instanceof Array){
                    if(this.$refs[refName].length > 0){
                        this.$refs[refName][0].focus();
                        if (this.$refs[refName][0]?.select) this.$refs[refName][0]?.select();
                        //手动打开下拉框
                        if(curCanKeyTableColumnListInfo.type == 'select'){
                            this.$refs[refName][0].toggleMenu(true);
                        }
                    }
                }else{
                    this.$refs[refName].focus();
                    if (this.$refs[refName]?.select) this.$refs[refName]?.select();
                    if(curCanKeyTableColumnListInfo.type == 'select'){
                        this.$refs[refName].toggleMenu(true);
                    }
                }
            }
        },
        //新增一行
        handleAddRow(rowindex,columnindex){
            this.tableKey = "";
            if(this.form){
                if(this.form.billDetailItems){
                    this.tableKey = this.form.billDetailItems;
                }else if(this.form.details){
                    this.tableKey = this.form.details;
                }
            }else{
                this.tableKey = this.tableData;
            }
            //此处记录tableKey是很多页面的tableData名字都不一样，所以最好页面单独传入
            if(this.hasTableKey){ 
                this.tableKey = this.hasTableKey;
            }
            if(rowindex+1==this.tableKey.length){
                //目前是在表格的最后一行数据，则默认新增一行
                if(this.noNeedPushRow){
                    return;
                }
                this.row('push',rowindex);
                //默认聚焦到下一行的第一聚焦点列
                let refName = this.realCanKeyTableColumnList[0].key+'_'+ (rowindex+1) +'_'+ this.realCanKeyTableColumnList[0].columnindex;
                this.$nextTick(()=>{
                    this.handleKeyFocus(columnindex,'rightEnter',refName);
                })
            }
        },
    }
}