import { encryptAes } from "@/utils/cryptoJS";
import { login, captchaLogin, logout, getInfo } from '@/api/login'
// import { TenantPermissionAPI} from '@/api/system/tenant'
import { getToken, setToken, removeToken } from '@/utils/auth'
import Cookies from 'js-cookie'

const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    roles: [],
    permissions: [],
    userinfo: {},
    productId: Cookies.get('productId'),
    tenantId: Cookies.get('keyId'),
    curTenant: '',
    heartbeat: 5
  },

  mutations: {
    SET_HEARTBEAT: (state, time) => {
      state.heartbeat = time
    },
    SET_USERINFO: (state, user) => {
      state.userinfo = user
    },
    SET_TOKEN: (state, token) => {
      state.token = token
      setToken(token)
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_PRODUCTID: (state, productId) => {
      state.productId = productId
      Cookies.set('productId', productId)
    },
    SET_TENANTID: (state, tenantId) => {
      state.tenantId = tenantId
      if (tenantId) {
        Cookies.set('keyId', tenantId)
      }
    },
    SET_CURTENANT: (state, curTenant) => {
      state.curTenant = curTenant
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      console.log(userInfo)
      return new Promise((resolve, reject) => {
        if (userInfo.type == 'username') {
          const username = encryptAes(userInfo.username.trim(), '80sunyunqOC0HHOI')
          const password = encryptAes(userInfo.password, '80sunyunqOC0HHOI')
          const tenantId = userInfo.tenantId
          const loginFrom = userInfo.loginFrom
          login(username, password, tenantId, loginFrom)
            .then(res => {
              setToken(res.token)
              commit('SET_TOKEN', res.token)
              commit('SET_HEARTBEAT', res?.heartbeat || 5)
              commit('SET_USERINFO', res.data)
              Cookies.set('keyId', res.data.tenantId)
              resolve(res)
            })
            .catch(error => {
              reject(error)
            })
        } else {
          captchaLogin(userInfo)
            .then(res => {
              setToken(res.token)
              commit('SET_TOKEN', res.token)
              commit('SET_HEARTBEAT', res?.heartbeat || 5)
              commit('SET_USERINFO', res.data)
              Cookies.set('keyId', res.data.tenantId)
              resolve(res)
            })
            .catch(error => {
              reject(error)
            })
        }
      })
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then(res => {
            res.roles = res.roles?.length <= 0 ? [{}] : res.roles
            const user = res.user
            const avatar =
              user.avatar == '' || user.avatar == null
                ? require('@/assets/images/profile.jpg')
                : process.env.VUE_APP_BASE_API + user.avatar
            if (res.roles && res.roles.length > 0) {
              // 验证返回的roles是否是一个非空数组
              commit('SET_ROLES', res.roles)
              commit('SET_PERMISSIONS', res.permissions)
            } else {
              commit('SET_ROLES', [['ROLE_DEFAULT']])
            }
            commit('SET_NAME', user.userName)
            commit('SET_USERINFO', user)
            commit('SET_AVATAR', avatar)
            commit('SET_CURTENANT', res.currentTenant)
            Cookies.set('keyId', user.tenantId)
            resolve(res)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token)
          .then(() => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            commit('SET_PERMISSIONS', [])
            commit('SET_PRODUCTID', '')
            commit('SET_TENANTID', '')
            removeToken()
            Cookies.remove('keyId')
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    //创建商户
    /* async CteateTenant({ commit },id){
         try{
           const res=await TenantPermissionAPI(id)
         if (res.data.roles && res.data.roles.length > 0) { // 验证返回的roles是否是一个非空数组
           commit('SET_ROLES', res.data.roles)
           commit('SET_PERMISSIONS', res.data.permissions)
         }
         }catch{}

         }, */
    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    }
  }
}

export default user
