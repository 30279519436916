const getData = ({ values, labels, valueKey = "value", labelKey = 'label' }) => {
    const data = Object.values(values)
    return Object.values(labels).map((x, i) => ({ [valueKey]: data[i], [labelKey]: x }))
}

// 升级日志管理-状态
export const UPGRADELOG_STATUS = {
    unpublished: "0",
    published: "1"
}

export const UPGRADELOG_STATUS_TEXT = {
    [UPGRADELOG_STATUS.unpublished]: '未发布',
    [UPGRADELOG_STATUS.published]: '已发布',
}

export const UPGRADELOG_STATUS_DATA = getData({ values: UPGRADELOG_STATUS, labels: UPGRADELOG_STATUS_TEXT })


// 升级类型（0 更新 1 升级）
export const UPGRADELOG_TYPE_STATUS = {
    unpublished: 0,
    published: 1
}

export const UPGRADELOG_TYPE_TEXT = {
    [UPGRADELOG_TYPE_STATUS.unpublished]: '更新',
    [UPGRADELOG_TYPE_STATUS.published]: '升级',
}

export const UPGRADELOG_TYPE_DATA = getData({ values: UPGRADELOG_TYPE_STATUS, labels: UPGRADELOG_TYPE_TEXT })

// 仓库报表 引入状态 单据状态 通用
export const ALLOT_STATUS = {
    unaudited: '0',
    review: '1',
    audited: '2',
    deleted: '3',
    closed: '4',
}

export const ALLOT_STATUS_TEXT = {
    [ALLOT_STATUS.unaudited]: '未审核',
    [ALLOT_STATUS.review]: '审核中',
    [ALLOT_STATUS.audited]: '已审核',
    [ALLOT_STATUS.deleted]: '已删除',
    [ALLOT_STATUS.closed]: '已关闭',
}

export const ALLOT_STATUS_DATA = getData({ values: ALLOT_STATUS, labels: ALLOT_STATUS_TEXT })

// 系统提醒 启用禁用
export const SYS_REMINDER_STATUS = {
    start: 1,
    stop: 0,
}

// 支付方式
export const O2O_PAY_TYPE = {
    wxPay: 1,
    vipPay: 2,
}

export const O2O_PAY_TYPE_TEXT = {
    [O2O_PAY_TYPE.wxPay]: '微信支付',
    [O2O_PAY_TYPE.vipPay]: '会员卡支付',
}

export const O2O_PAY_TYPE_DATA = getData({ values: O2O_PAY_TYPE, labels: O2O_PAY_TYPE_TEXT })

// 商城模板-模板类型
export const TEMPLATE_TYPE = {
    all: 0,
    index: 1,
    class: 2,
    detail: 3,
    center: 4,
    navigation: 5,
    custom: 6,
}
export const TEMPLATE_TYPE_TEXT = {
    [TEMPLATE_TYPE.all]: '全部页面',
    [TEMPLATE_TYPE.index]: '商城首页',
    [TEMPLATE_TYPE.class]: '分类页',
    [TEMPLATE_TYPE.detail]: '商品详情',
    [TEMPLATE_TYPE.center]: '会员中心',
    [TEMPLATE_TYPE.navigation]: '底部导航',
    [TEMPLATE_TYPE.custom]: '自定义页面',
}
export const TEMPLATE_TYPE_DATA = getData({ values: TEMPLATE_TYPE, labels: TEMPLATE_TYPE_TEXT })


// 订单状态 订单状态枚举（1=待付款或预订中 2=生产中 3=已入库 4=待发货或取货中 5=已发货或已取货 6=已完成 100=已取消或取消预订 101=已关闭 102=已删除）-->
// export const O2O_ORDER_STATUS = {
//     awaitPay: 1,
//     awaitFetch: 9999,
//     awaitFetchTwo: 9998,
//     fetch: 5,
//     complete: 7,
//     cancel: 100,
//     awaitRefund: -1,
//     refund: -2,
//     refuseRefund: 1,
// }

// export const O2O_ORDER_STATUS_TEXT = {
//     [O2O_ORDER_STATUS.awaitPay]: '待付款',
//     [O2O_ORDER_STATUS.awaitFetch]: '待发货',
//     [O2O_ORDER_STATUS.awaitFetchTwo]: '待提货',
//     [O2O_ORDER_STATUS.fetch]: '已发货',
//     [O2O_ORDER_STATUS.complete]: '已完成',
//     [O2O_ORDER_STATUS.cancel]: '已取消',
//     [O2O_ORDER_STATUS.awaitRefund]: '待退款',
//     [O2O_ORDER_STATUS.refund]: '已退款',
//     [O2O_ORDER_STATUS.refuseRefund]: '拒绝退款',
// }

// // 请求参数
// export const O2O_ORDER_STATUS_PARAMS = {
//     [O2O_ORDER_STATUS.awaitPay]: {orderStatus:1},    this.queryParams.orderStatus = 4;
//     this.queryParams.fetchType = 2;
//     this.queryParams.isToBeShipped = true;
//     [O2O_ORDER_STATUS.awaitFetch]: {orderStatus:4},
//     [O2O_ORDER_STATUS.awaitFetchTwo]: '待提货',
//     [O2O_ORDER_STATUS.fetch]: '已发货',
//     [O2O_ORDER_STATUS.complete]: '已完成',
//     [O2O_ORDER_STATUS.cancel]: '已取消',
//     [O2O_ORDER_STATUS.awaitRefund]: '待退款',
//     [O2O_ORDER_STATUS.refund]: '已退款',
//     [O2O_ORDER_STATUS.refuseRefund]: '拒绝退款',
// }

// export const O2O_ORDER_STATUS_DATA = getData({ values: O2O_ORDER_STATUS, labels: O2O_ORDER_STATUS_TEXT })

